import "./services/sentry/init"; // Sentry Initialization as early as possible
import "./styles/App.scss";
import "./styles/index.css";

import { CircularProgress } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import React, { Suspense, useState } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Navigate, Route } from "react-router-dom";
import * as VerdiAPI from "verdiapi";
import { dayMs, secondMs } from "verdiapi/dist/HelperFunctions";
import { setProcessingFunction } from "verdiapi/dist/Models/AMasterIndex";

import AllComponentDemos from "./components/generic/AllComponentDemos/AllComponentDemos";
import AllOnLoadMessages from "./components/specialized/OnLoadMessages/AllOnLoadMessages";
import { ModalProvider } from "./hooks/useModal/ModalContext";
import NavBar from "./modules/navigation/NavBar";
import { AdminUserManager } from "./pages/AdminUserManager/AdminUserManager";
import CustomGraphPage from "./pages/CustomGraphPage";
import { DataExport } from "./pages/DataExport/DataExport";
import GraphDemoPage from "./pages/GraphDemoPage";
import Login from "./pages/Login";
import TestBed from "./pages/TestBed";
import TypographyDemoPage from "./pages/TypographyDemoPage";
import UserAccountManager from "./pages/UserAccountManager";
import GloballyControlledLoadingSpinner from "./services/LoadingSpinner/GloballyControlledLoadingSpinner";
import FocusContext from "./services/mapManagement/FocusContext";
import AreaofInterestMapEntity from "./services/mapManagement/mapEntities/AreaOfInterestMapEntity";
import DeviceMapEntity from "./services/mapManagement/mapEntities/DeviceMapEntity";
import IrrigationSetMapEntity from "./services/mapManagement/mapEntities/IrrigationSetMapEntity";
import OverlayEntity from "./services/mapManagement/mapEntities/OverlayEntity";
import { ThirdPartyDeviceMapEntity } from "./services/mapManagement/mapEntities/ThirdPartyDeviceMapEntity";
import ZoneMapEntity from "./services/mapManagement/mapEntities/ZoneMapEntity";
import { SentryRoutes, setSentryUser } from "./services/sentry";
import { doInitialMapPan, doInitialScheduleLoad } from "./services/sessionManager/onAccountLoad";
import { setGoogleAnalyticsTags } from "./services/sessionManager/setGoogleAnalyticsTags";
import { setAccountAndStartSessionLoad, setupVerdiAPI } from "./services/sessionManager/setupVerdiAPI";
import { MUItheme } from "./styles/ColourPalette";
import GlobalOptions from "./utils/GlobalOptions";
import { preventAccidentalPageZooming } from "./utils/preventAccidentalPageZooming";
import { URLParams } from "./utils/URLParams";
// Flag to check if listener is added
let isListenerAdded = false;

// Get the device memory
const deviceMemory = navigator.deviceMemory;

// Log some useful information
console.info(`Device memory (GB): ${navigator.deviceMemory}`);
console.info(`Cloud Build ID: ${process.env.REACT_APP_BUILD_ID}`);
console.info(`Cloud Build Commit SHA: ${process.env.REACT_APP_COMMIT_SHA}`);

// Set the initial date to load images to the current date
let dateToLoadImages = Date.now();

// Adjust the date to load NDVI images based on device memory and device type
if (deviceMemory) {
    if (deviceMemory >= 6 && !isMobile) {
        // If device memory is 6 or more and the device is not mobile, set the date to 30 days ago
        dateToLoadImages -= dayMs * 30;
    } else if (deviceMemory >= 4) {
        // If device memory is 4 or more, set the date to 15 days ago
        dateToLoadImages -= dayMs * 15;
    }
}

// Lazy load the Map page
const MapPage = React.lazy(
    () =>
        new Promise((resolve, reject) => {
            import("./pages/Map")
                .then((result) => resolve(result.default ? result : { default: result }))
                .catch(reject);
        }),
);

// Lazy load the Settings page
const SettingsPage = React.lazy(
    () =>
        new Promise((resolve, reject) => {
            import("./pages/Settings")
                .then((result) => resolve(result.default ? result : { default: result }))
                .catch(reject);
        }),
);

// Lazy load the Debugging page
const DebuggerPage = React.lazy(
    () =>
        new Promise((resolve, reject) => {
            import("./pages/DebuggingMainPage")
                .then((result) => resolve(result.default ? result : { default: result }))
                .catch(reject);
        }),
);

// Set the font size of the root element to 16px for mobile devices
if (isMobile) {
    document.children[0].style.fontSize = "16px";
}

// Initialize an empty array to hold map entities
let mapEntities = [];

// Define a function to process different model types
setProcessingFunction((model) => {
    try {
        // Mapping of model categories to their respective classes
        const categoryClassMap = {
            irrigationDevice: DeviceMapEntity,
            blockValve: DeviceMapEntity,
            doubleMoistureDevice: DeviceMapEntity,
            SensoterraMoisture: DeviceMapEntity,
            zone: ZoneMapEntity,
            overlay: OverlayEntity,
            aoi: AreaofInterestMapEntity,
            irrigationSet: IrrigationSetMapEntity,
            thirdPartyDevice: ThirdPartyDeviceMapEntity,
        };

        // Get the class for the model category
        const EntityClass = categoryClassMap[model.category];

        // Check if the category is known
        if (EntityClass) {
            // Handle special case for overlay entity
            if (model.category === "overlay") {
                mapEntities.push(new EntityClass(model, new Date(model.date).valueOf() > dateToLoadImages));

                // Handle all other model categories
            } else {
                mapEntities.push(new EntityClass(model, true));
            }

            // Log a warning if the category is unknown
        } else {
            console.info("Unknown model of category: ", model.category);
        }
    } catch (e) {
        console.warn("Error assigning VerdiClient entity to MapEntity", e);
    }

    // Clear the array for the next processing cycle
    mapEntities = [];
});

// makes sure that zooming only zooms the map and not the page.
preventAccidentalPageZooming();

// Log the contents of /humans.txt to the console
fetch("/humans.txt")
    .then((response) => response.text())
    .then((data) => {
        console.info(`Humans.txt:\n`, data);
    })
    .catch((error) => {
        console.warn("Error fetching the humans.txt file:", error);
    });

function App() {
    // Set the favicon based on the REACT_APP_ENVIRONMENT environment variable.
    if (process.env.REACT_APP_ENVIRONMENT) {
        const iconTypes = ["apple-touch-icon", "favicon-32x32", "favicon-16x16"];
        iconTypes.forEach((iconType) => {
            const iconLink = document.getElementById(iconType);
            if (iconLink) {
                iconLink.href = `./favicon/${process.env.REACT_APP_ENVIRONMENT}/${iconType}.png`;
            }
        });
    } else {
        console.warn(
            "REACT_APP_ENVIRONMENT is not set in the environment variables. Environment variables: ",
            process.env,
        );
    }

    // State for tracking login status
    const [isLoginLoaded, setIsLoginLoaded] = useState(false);

    // State for tracking if user is logged in
    const [isLoggedIn, setIsLoggedIn] = useState(undefined);

    // State for tracking position
    const [position, setPosition] = useState(undefined);

    // If the listener is not added, add it
    if (!isListenerAdded) {
        console.info(`TIME: adding listeners ${Date.now() % 10000}`);

        setupVerdiAPI();

        // Add a listener to handle login events in VerdiAPI's SessionHandler
        VerdiAPI.SessionHandler.onLogin.addListener((e) => {
            console.info(`TIME: onlogin listener activated ${Date.now() % 10000}`);
            localStorage.setItem("userData", JSON.stringify(e));
            setGoogleAnalyticsTags();

            console.info(`TIME: starting change users/load session ${Date.now() % 10000}`);

            setAccountAndStartSessionLoad();

            setIsLoggedIn(true);
            setIsLoginLoaded(true);
        });

        VerdiAPI.SessionHandler.onSessionLoad.addListener(async () => {
            FocusContext.updateLoadHandler("Processing Data", "sessionLoad");
            console.info("####Session Loaded####");

            // Sentry - add user info to the Sentry SDK
            setSentryUser();

            // honestly, I have no idea what this does anymore. Something to do with google analytics
            if (VerdiAPI.SessionHandler.admin) {
                window.dataLayer.push({
                    sessionLoadedAdmin: 1,
                });
            } else {
                // eslint-disable-next-line no-undef
                gtag("event", "page_view", {
                    page_title: "Dashboard",
                    client_id: VerdiAPI.SessionHandler.admin ? "ADMIN" : VerdiAPI.SessionHandler.curUser,
                    user_agent: window.navigator.userAgent,
                });
            }

            console.info("Setting account URL parameter...");

            try {
                if (VerdiAPI.SessionHandler.admin) {
                    URLParams.setParam("account", VerdiAPI.SessionHandler.currentUserObject.id);
                }
            } catch (e) {
                console.warn("error setting current user account", e);
            }

            // wait for async tasks to complete
            await Promise.race([Promise.allSettled([doInitialMapPan(), doInitialScheduleLoad()]), 10 * secondMs]);
            FocusContext.resolveLoadHandler("sessionLoad");
        });

        // Add a listener to handle logout events in VerdiAPI's SessionHandler
        VerdiAPI.SessionHandler.onLogout.addListener(() => {
            // Remove the user data from local storage on logout
            localStorage.removeItem("userData");
        });

        let storedData = {};
        try {
            storedData = JSON.parse(localStorage.getItem("userData"));
        } catch (e) {
            console.warn(e);
        }

        if (storedData && storedData.token) {
            if (storedData.defaultPos) {
                VerdiAPI.SessionHandler.defaultPosition = storedData.defaultPos;
            }
            const accountParam = URLParams.getParam("account");
            if (accountParam) {
                VerdiAPI.SessionHandler.curUser = accountParam;
                console.info(`set cur user of session param at ${Date.now() % 10000}`);
            }

            console.info(`TIME: starting login with token ${Date.now() % 10000}`);
            VerdiAPI.SessionHandler.loginWithToken(storedData.token).then((r) => {
                console.info(`TIME: done loggin in with token ${Date.now() % 10000}`);
                if (r.token) {
                    setPosition(r.defaultPos);
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
                setIsLoginLoaded(true);
            });
        } else {
            setIsLoggedIn(false);
            setIsLoginLoaded(true);
        }

        // Add the d and m classes to the root element to make style rules for mobile and desktop work properly
        const rootElement = document.getElementById("root");
        if (rootElement) {
            if (isMobile) {
                rootElement.classList.add("m");
                rootElement.style.setProperty("--nav-bar-height", "45px");
                document.body.style.setProperty("--nav-bar-height", "45px");
            } else {
                rootElement.classList.add("d");
            }

            if (GlobalOptions.iconSize) {
                rootElement.classList.add(`iconSize--${GlobalOptions.iconSize}`);
            }
        }

        isListenerAdded = true;
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={MUItheme}>
                <SnackbarProvider autoHideDuration={7 * secondMs} maxSnack={3}>
                    <ModalProvider>
                        <Router>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Suspense fallback={<CircularProgress />}>
                                    <NavBar />
                                    <AllOnLoadMessages />
                                    <GloballyControlledLoadingSpinner />
                                    <RoutesComponent
                                        isLoginLoaded={isLoginLoaded}
                                        isLoggedIn={isLoggedIn}
                                        position={position}
                                    />
                                </Suspense>
                            </LocalizationProvider>
                        </Router>
                    </ModalProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

// If the user is logged in, it shows the main application routes.
function RoutesComponent({ isLoginLoaded, isLoggedIn, position }) {
    // Check if the login status is loaded
    if (!isLoginLoaded) {
        return <>Loading...</>;
    }

    // Check if the user is logged in
    if (!isLoggedIn) {
        return (
            <SentryRoutes>
                <>
                    <Route path={"/auth"} element={<Login />} />
                    <Route path={"/"} element={<Navigate replace to={"/auth"} />} />
                    <Route path={"/ComponentDemo"} exact element={<AllComponentDemos />} />
                </>
            </SentryRoutes>
        );
    }

    // Log the session handler for debugging
    console.info("Session Handler", VerdiAPI.SessionHandler);

    return (
        <SentryRoutes>
            <>
                <Route
                    path={"/"}
                    exact
                    element={
                        <React.Suspense fallback={"Loading..."}>
                            <MapPage defaultPosition={VerdiAPI.SessionHandler.defaultPosition} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"/settings"}
                    exact
                    element={
                        <React.Suspense fallback={"Loading..."}>
                            <SettingsPage defaultPosition={position} />
                        </React.Suspense>
                    }
                />
                <Route path={"/test"} exact element={<TestBed />} />
                <Route path={"/typography"} exact element={<TypographyDemoPage />} />
                <Route path={"/GraphDemo"} exact element={<GraphDemoPage />} />
                <Route path={"/customGraph"} exact element={<CustomGraphPage />} />
                <Route
                    path={"/DeviceDebugger"}
                    exact
                    element={
                        <React.Suspense fallback={"Loading..."}>
                            <DebuggerPage />
                        </React.Suspense>
                    }
                />
                <Route path={"/export"} exact element={<DataExport />} />
                <Route path={"/UserManager"} exact element={<UserAccountManager />} />
                <Route path={"/AdminUserManager"} exact element={<AdminUserManager />} />
                <Route
                    path={"/ComponentDemo"}
                    exact
                    element={
                        <React.Suspense fallback={"Loading..."}>
                            <AllComponentDemos />
                        </React.Suspense>
                    }
                />
                <Route path={"/auth"} element={<Navigate replace to={"/"} />} />
            </>
        </SentryRoutes>
    );
}

export default App;
