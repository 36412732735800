import "./ZoneIrrigationScheduleBar.scss";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { BufferedAction, GenerateUID, sleep } from "gardenspadejs/dist/general";
import React from "react";
import { EventHandler } from "verdiapi";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";

import DynamicGraphHeader from "../DynamicGraph/DynamicGraphHeader";
import { DynamicGraphContext } from "../DynamicGraph/DynamicGraphUtility";

const InfoBoxTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            height: "100%",
            fontSize: 11,
        },
    }),
);
/**
 * @deprecated
 */
export default class ZoneIrrigationScheduleBar extends React.Component {
    totalHours = [0, 0];

    /**
     * @type {HistoricalDataBase}
     */
    zoneHistoryDatabase;

    constructor(props) {
        super(props);
        this.uid = GenerateUID("ZoneScheduleBar");
        if (this.props.zoneID) {
            this.zoneHistoryDatabase = new HistoricalDataBase(
                [this.props.zoneID],
                ["zoneIrrigationScheduled", "valveOpenPercent"],
            );
        } else if (this.props.deviceID) {
            this.zoneHistoryDatabase = new HistoricalDataBase([this.props.deviceID], ["valveState"]);
        }
        this.zoneHistoryDatabase.worryAboutPosition = false;
    }

    componentDidMount() {
        // staggers data loading
        setTimeout(
            () => {
                this.zoneHistoryDatabase
                    .getData(new Date(this.startTime.valueOf() - 1000 * 60 * 60 * 24 * 30 * 10), this.endTime)
                    .then(() => {
                        this.forceUpdate();
                    })
                    .catch((e) => {
                        console.warn("error fetching schedule history", e);
                    });
            },
            10 + Math.random() * 800,
        );

        const action = async () => {
            await sleep(10 + Math.random() * 500);
            this.zoneHistoryDatabase
                .getData(
                    new Date(this.context.startTime.valueOf()),
                    new Date(Math.ceil(Date.now() / (60000 * 60 * 24)) * 60000 * 60 * 24),
                )
                .then(() => {
                    this.forceUpdate();
                })
                .catch((e) => {
                    console.warn("error fetching schedule history", e);
                });
        };

        const bufferedAction = new BufferedAction(action, 1500, true, false);

        this.context.onDateRangeChange.addListener(() => {
            bufferedAction.trigger();
            this.forceUpdate();
        }, this.uid);
        this.context.onCursorPositionChanged.addListener(() => {
            this.forceUpdate();
        }, this.uid);
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    get endTime() {
        return this.props.endTime || this.context.endTime;
    }

    get startTime() {
        return this.props.startTime || this.context.startTime;
    }

    drawStrips() {
        const dynamicWidth = this.props.modalOpen === true ? (90 * window.innerWidth) / 100 : this.props.width;
        const allStrips = [];
        const stripDimensions = this.generateStripObjects(
            this.startTime,
            this.endTime,
            dynamicWidth,
            this.zoneHistoryDatabase.data.value,
        );

        allStrips.push(
            ...stripDimensions.map((strip, i) => {
                const title = (
                    <div style={{ whiteSpace: "pre-line" }}>{`Scheduled Start Time: ${`${strip.startTime.getHours()}:${
                        strip.startTime.getMinutes() < 10 ? "0" : ""
                    }${strip.startTime.getMinutes()}`}
                Scheduled End Time: ${`${strip.endTime.getHours()}:${
                    strip.endTime.getMinutes() < 10 ? "0" : ""
                }${strip.endTime.getMinutes()}`}`}</div>
                );

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <InfoBoxTooltip title={title} key={`striptwo${i}`}>
                        <div
                            className={"staticIrrigationGraphStrips"}
                            style={{ width: strip.width, left: strip.left }}
                        />
                    </InfoBoxTooltip>
                );
            }),
        );
        const actualStripDimensions = this.generateStripObjects(
            this.startTime,
            this.endTime,
            dynamicWidth,
            this.zoneHistoryDatabase.data.value,
            "actual",
        );
        allStrips.push(
            ...actualStripDimensions.map((strip, i) => {
                const title = (
                    <div style={{ whiteSpace: "pre-line" }}>{`Actual Start Time: ${`${strip.startTime.getHours()}:${
                        strip.startTime.getMinutes() < 10 ? "0" : ""
                    }${strip.startTime.getMinutes()}`}
                    Actual End Time: ${`${strip.endTime.getHours()}:${
                        strip.endTime.getMinutes() < 10 ? "0" : ""
                    }${strip.endTime.getMinutes()}`}`}</div>
                );

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <InfoBoxTooltip title={title} key={`stripone${i}`}>
                        <div
                            className={"staticIrrigationGraphStrips--actual"}
                            style={{ width: strip.width, left: strip.left }}
                        />
                    </InfoBoxTooltip>
                );
            }),
        );
        return allStrips;
    }

    /**
     * Generats objects to represent the specifications of all irrigation strips
     * @param startTime
     * @param endTime
     * @param width
     * @param data
     * @return {*[]}[]
     */
    generateStripObjects(startTime, endTime, width, data, type = "intended") {
        this.totalHours[type === "intended" ? 0 : 1] = 0;
        const getBoolean = (dataPoint) => {
            if (this.props.deviceID) {
                if (type === "intended") {
                    return dataPoint.valveState % 2 === 1;
                }
                if (type === "actual") {
                    return Math.floor(dataPoint.valveState / 2) % 2 === 1;
                }
            }
            if (type === "intended") {
                if (dataPoint.zoneIrrigationScheduled !== undefined && dataPoint.zoneIrrigationScheduled !== null) {
                    return dataPoint.zoneIrrigationScheduled > 0.25;
                }
                return undefined;
            }
            if (type === "actual") {
                if (dataPoint.valveOpenPercent !== undefined && dataPoint.valveOpenPercent !== null) {
                    return dataPoint.valveOpenPercent > 0.5;
                }
                return undefined;
            }
            return undefined;
        };
        let valvesOpenAtStart = false;
        let i;

        for (i = 0; i < data.length && data[i].date.valueOf() < startTime.valueOf(); i++) {
            valvesOpenAtStart = getBoolean(data[i].getDeviceData(this.props.zoneID));
        }

        let valveIsCurrentlyOpen = valvesOpenAtStart;

        const strips = [];
        let newStrip = {
            startTime: new Date(startTime.valueOf()),
            duration: undefined,
            endTime: new Date(endTime.valueOf()),
        };

        for (; i < data.length && data[i].date.valueOf() < (endTime || Date.now()).valueOf(); i++) {
            const rawData = data[i].getDeviceData(this.props.zoneID || this.props.deviceID);
            const valveWillOpen = getBoolean(rawData);
            if (valveWillOpen !== undefined) {
                if (!valveIsCurrentlyOpen && valveWillOpen) {
                    newStrip = {
                        startTime: new Date(data[i].date.valueOf()),
                        duration: undefined,
                        endtime: new Date(endTime.valueOf()),
                    };
                }
                if (valveIsCurrentlyOpen && !valveWillOpen) {
                    newStrip.duration = data[i].date.valueOf() - newStrip.startTime.valueOf();
                    newStrip.endTime = new Date(newStrip.startTime.valueOf() + newStrip.duration);
                    strips.push(newStrip);
                }
                valveIsCurrentlyOpen = valveWillOpen;
            }
        }
        if (valveIsCurrentlyOpen) {
            newStrip.duration = Date.now() - newStrip.startTime.valueOf();
            newStrip.endTime = new Date(newStrip.startTime.valueOf() + newStrip.duration);
            strips.push(newStrip);
        }
        const pxPerMs = width / (this.endTime.valueOf() - this.startTime.valueOf());
        return strips.map((strip) => {
            if (this.totalHours) {
                this.totalHours[type === "intended" ? 0 : 1] += strip.duration;
            }
            strip.width = pxPerMs * strip.duration * (this.props.scaleFactor || 1);
            strip.left = pxPerMs * (strip.startTime.valueOf() - this.startTime.valueOf());
            return strip;
        });
    }

    render() {
        const strips = this.drawStrips();
        const hours = 1000 * 60 * 60;
        const dynamicWidth = this.props.modalOpen === true ? (90 * window.innerWidth) / 100 : this.props.width;
        const dynamicHeight = this.props.modalOpen === true ? (3 * window.innerHeight) / 100 : this.props.height;

        return (
            <>
                <div className={"staticIrrigationGraphInfo"}>
                    <div className={"staticIrrigationGraphInfo__text  staticIrrigationGraphInfo__datetext"}>
                        <DynamicGraphHeader enlarged={this.props.scaleFactor} />
                    </div>
                    <div className={"staticIrrigationGraphInfo__wrapper"}>
                        <div className={"staticIrrigationGraphInfo__text"}>
                            <div
                                className={
                                    "staticIrrigationGraphInfo__text--block staticIrrigationGraphInfo__text--intended"
                                }
                            />
                            Scheduled Total #:{" "}
                            <span style={{ minWidth: "3ch", textAlign: "right" }}>
                                {Math.round(this.totalHours[0] / hours)}
                            </span>{" "}
                            h
                        </div>
                        <div className={"staticIrrigationGraphInfo__text"}>
                            <div
                                className={
                                    "staticIrrigationGraphInfo__text--block staticIrrigationGraphInfo__text--actual"
                                }
                            />
                            Device Valve Open:{" "}
                            <span style={{ minWidth: "3ch", textAlign: "right" }}>
                                {Math.round(this.totalHours[1] / hours)}
                            </span>{" "}
                            h
                        </div>
                    </div>
                </div>

                <div
                    className={"staticIrrigationGraphBackground"}
                    style={{ width: dynamicWidth, height: dynamicHeight }}
                >
                    {strips}
                </div>
            </>
        );
    }
}

ZoneIrrigationScheduleBar.contextType = DynamicGraphContext;
