/* eslint-disable no-bitwise */
import { Graphics } from "@pixi/react";
import * as PText from "@pixi/text";
import { BufferedAction } from "gardenspadejs/dist/general";
import { Rectangle } from "pixi.js";
import React from "react";
import { EventHandler } from "verdiapi";
import { GenerateUID } from "verdiapi/dist/HelperFunctions";

import { DynamicGraphContext } from "../DynamicGraphUtility";

export default class TrendGraphCursorLine extends React.Component {
    getCursorTimeBufferedAction;

    closestCursorValue = undefined;

    cursorValues = [];

    g = undefined;

    k = 0;

    lastPosition;

    lineColor = 0xafafaf;

    toolTipText = undefined;

    /**
     * These are all the data dots that you see on the cursor line. Stored here so that the mouseMoveEventListener
     * can access them.
     */
    allCursorCircles = [];

    /**
     * Defined in the constructor, should not be re-assigned.
     * @type {(event)=>void}
     */
    mouseMoveEventListener = undefined;

    constructor(props) {
        super(props);
        this.uid = GenerateUID("TrendGraphCursorLine");
    }

    componentDidMount() {
        this.getCursorTimeBufferedAction = new BufferedAction(
            () => {
                const timeOfCursor = this.props.getCursorStringAsFunctionOfX(this.lastPosition);
                this.cursorValues = [];
                Object.values(this.context.lines).forEach((line) => {
                    const nearestPoint = line.getNearestValue(timeOfCursor);
                    let nearestValue;
                    let y = 0;
                    if (nearestPoint) {
                        try {
                            nearestValue = nearestPoint;
                            y =
                                (1 - this.context.staticGraphDictionary[line.sensorType].getYPercentage(nearestValue)) *
                                this.props.height;
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                    const graphType = this.context.staticGraphDictionary[line.sensorType];
                    this.cursorValues.push({
                        name: line.name,
                        sensorType: line.sensorType,
                        unit: graphType.unit,
                        value: graphType.convertValue(nearestValue),
                        // unit: this.context.staticGraphDictionary[line.sensorType].unit,
                        // value: nearestValue,
                        color: line.color,
                        uid: line.uid,
                        y: y,
                    });
                });
                this.context.cursorTime = timeOfCursor;
                this.context.cursorPosition = [this.lastPosition, 0];
                this.context.onCursorPositionChanged.trigger();
                this.redraw();
            },
            20,
            false,
            false,
        );

        this.props.mouseMoveEvent.addListener((e) => {
            this.lastPosition = e.clientX - e.target.getBoundingClientRect().left;
            this.getCursorTimeBufferedAction.trigger();
            this.redraw();
        });

        // a handler for mouse movement emerging from the graphics object
        // listener is added in the render method so that this actually fires
        // this handles figuring out when to display the tooltip.
        this.mouseMoveEventListener = (event) => {
            let closestCircle = null;
            this.allCursorCircles.forEach((c) => {
                if (this.context.lines[c.uid].focused) {
                    if (Math.abs(c.y - (event.data.global.y - (this.props.y || 0))) < 10) {
                        closestCircle = c;
                    }
                }
            });
            this.closestCursorValue = closestCircle;
            this.redraw();
        };
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    _doRedraw() {
        if (!this.g) {
            return;
        }
        this.g.zIndex = 150;
        this.k++;
        try {
            try {
                this.g.clear();
            } catch (e) {
                console.warn(e);
            }
            this.g.endFill();

            this.g.beginFill(0xffffff, 0);
            this.g.lineStyle(1.5, this.lineColor, 1);
            this.g.moveTo(this.lastPosition, 0);
            this.g.lineTo(this.lastPosition, this.props.height);
            this.g.endFill();
            this.g.lineStyle(0, this.lineColor, 0);
            this.g.beginFill(0xff0000, 1);

            this.g.interactive = true;
            this.g.hitArea = new Rectangle(this.lastPosition - 10, this.props.y || 0, 20, this.props.height);
            const allCircles = this.cursorValues;

            this.g.endFill();
            const lineColors = [];
            allCircles.forEach((c) => {
                if (this.context.lines[c.uid].focused) {
                    this.g.beginFill(rgba2hex(c.color), 1);
                    this.g.drawCircle(this.lastPosition, c.y, 4);
                    this.g.endFill();
                    lineColors.push(c.color);
                }
            });
            const tooltipHeight = this.props.isModalOpen === true ? 24 : 18;
            const tooltipBoxLeftPadding = 10;
            const tooltipBoxLeftMargin = 7;
            if (this.closestCursorValue) {
                const textString = `${this.closestCursorValue.name}: ${
                    Math.round(this.closestCursorValue.value * 10) / 10
                } ${this.closestCursorValue.unit}`;
                let textWidth = 20;
                if (!this.toolTipText || !this.g.children.includes(this.toolTipText)) {
                    this.toolTipText = new PText.Text(textString, {
                        fontFamily: "Roboto",
                        fontSize: this.props.isModalOpen === true ? 14 : 10,
                        fill: 0xffffff,
                    });
                    this.toolTipText.x = this.lastPosition - tooltipBoxLeftMargin - tooltipBoxLeftPadding;
                    this.toolTipText.y = this.closestCursorValue.y;
                    this.toolTipText.alpha = 1;
                    this.toolTipText.anchor.set(1, 0.5);
                    this.g.addChild(this.toolTipText);
                } else {
                    this.toolTipText.text = textString;
                    this.toolTipText.fontSize = this.props.isModalOpen === true ? 14 : 10;
                    this.toolTipText.x = this.lastPosition - tooltipBoxLeftMargin - tooltipBoxLeftPadding;
                    this.toolTipText.alpha = 1;
                    this.toolTipText.y = this.closestCursorValue.y;
                    textWidth = this.toolTipText.getBounds().width + tooltipBoxLeftPadding * 2;
                }
                this.g.beginFill(0x5a5a5a, 1);
                this.g.lineStyle(3, rgba2hex(this.closestCursorValue.color));
                this.g.drawRoundedRect(
                    this.lastPosition - textWidth - tooltipBoxLeftMargin,
                    this.closestCursorValue.y - tooltipHeight / 2,
                    textWidth,
                    tooltipHeight,
                    3,
                );
                this.g.endFill();
            } else if (this.toolTipText) {
                this.toolTipText.alpha = 0;
            }

            // make sure this graphics object is capturing events
            this.g.eventMode = "static";
            this.g.interactive = true;

            // this is so that the mouseMoveEventListener can know where the current cusor data dots (or cursor circles)
            // are being drawn. Not renaming right now because this isn't a refactor, just a bug fix.
            this.allCursorCircles = allCircles;

            // Fire the mouseMoveEventListener whenever the pointer moves. Because "this.mouseMoveEventListener" is
            // defined in the constructure, it will only actually be added if it isn't presently listening.
            this.g.addListener("pointermove", this.mouseMoveEventListener);
        } catch (e) {
            console.warn(e);
        }
    }

    redraw(g = undefined) {
        if (g) {
            this.g = g;
        }
        if (!this.g) {
            return;
        }
        this.k++;
        const temp = this.k;
        window.requestAnimationFrame(() => {
            if (temp !== this.k) {
                return;
            }
            this._doRedraw();
        });
    }

    render() {
        return (
            <Graphics
                y={this.props.y || 0}
                draw={(g) => {
                    this.redraw(g);
                }}
                options={{
                    interactive: this.props.interactive,
                }}
            />
        );
    }
}

TrendGraphCursorLine.contextType = DynamicGraphContext;

function rgba2hex(orig) {
    if (orig.includes("#")) {
        let hexColor = orig;
        hexColor = hexColor.replace("#", "");
        hexColor = parseInt(hexColor, 16);
        return hexColor;
    }

    const rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    ((rgb && rgb[4]) || "").trim();
    const hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : orig;
    return parseInt(hex, 16);
}
//
// TrendGraphCursorLine.propTypes = {
//     getCursorStringAsFunctionOfX: PropTypes.func,
//     height: PropTypes.number,
//     mouseMoveEvent: PropTypes.func,
//     allLines: PropTypes.array,
//     interactive: PropTypes.bool,
// };
