import * as Sentry from "@sentry/react";
import { Routes } from "react-router-dom";
import * as VerdiAPI from "verdiapi";

import { getWhoAmI, WhoAmIResponse } from "../../pages/AdminUserManager/api";

interface MeObject extends WhoAmIResponse {
    curUser: string;
    accounts: Account[];
}

interface Account {
    [key: string]: any;
    id: string;
}

/**
 * Sentry - add user info to the Sentry SDK
 */
export async function setSentryUser() {
    const me = (await getWhoAmI()) as MeObject;
    const { adminUsername, admin, id, curUser: currentUserId } = me;

    const currentAccount = VerdiAPI?.SessionHandler?.currentUserObject ?? undefined;

    Sentry.setUser({
        id: currentUserId,
        username: currentAccount?.username,
        name: currentAccount?.name,
        admin: {
            isAdmin: admin,
            adminUsername,
            adminId: id,
        },
    });
}

/**
 * Sentry routes component for performance monitoring
 */
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
