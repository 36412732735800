import { sleep } from "gardenspadejs/dist/general";
import * as VerdiAPI from "verdiapi";
import { MasterIndex, Schedule } from "verdiapi";
import { doTick } from "verdiapi/dist/Models";
import { IrrigationDeviceBase } from "verdiapi/dist/Models/Devices/IrrigationDeviceBase";

import { getCenterOfPolygon } from "../../utils/getCenterOfPolygon";
import GlobalOptions from "../../utils/GlobalOptions";
import { URLParams } from "../../utils/URLParams";
import FocusContext from "../mapManagement/FocusContext";
import MapEntityBase from "../mapManagement/mapEntities/MapEntityBase";
import { getStartingPositionOfMap, MapLoadedPromise, setStartingPositionOfMap } from "../mapManagement/MapHelper";

export function getIDOfInitialField() {
    const fieldParam = URLParams.getParam("field");
    if (fieldParam) {
        return fieldParam;
    }

    if (GlobalOptions.defaultStartingField) {
        return GlobalOptions.defaultStartingField;
    }

    return undefined;
}

export function getIDOfInitialDevice() {
    return URLParams.getParam("dev");
}

export async function findDeviceByIDEventually(deviceID: string): Promise<IrrigationDeviceBase | undefined> {
    const collectionsToSearchForDeviceIn = ["blockValve", "irrigationDevice", "thirdPartyDevice"];

    // TS really hates indexing the master index with strings. But I'm doing it anyway. So it means a lot of ts-ignores
    // @ts-ignore
    // prettier-ignore
    return Promise.race(
        // @ts-ignore
        collectionsToSearchForDeviceIn.map((collectionToSearch) =>
            ( // @ts-ignore
                VerdiAPI.MasterIndex[collectionToSearch] as unknown as SingleZoneCategory<
                    // @ts-ignore
                    ThirdPartyDeviceBase | IrrigationDevice | BlockValve
                >
            )
                // @ts-ignore
                .byIDeventually(deviceID)
                .catch(() => {
                    console.warn("couldn't find device specified in device parameter");
                    return undefined;
                }),
        ),
    );
}

/**
 * Pans the map to wherever it should start. This is based on URL paramaters like dev and field
 * as well as the last field the user selected (stored in "lastSelectedFieldIDByUserID")
 * @return {Promise<void>}
 */
export async function doInitialMapPan() {
    // identify what devices or fields need panning to based on url parameters and local storage
    const idOfDeviceToPanTo = getIDOfInitialDevice();
    const idOfFieldToPanTo = getIDOfInitialField();

    // if we are going to pan to a device, ignore the field and just go to the device
    if (idOfDeviceToPanTo) {
        // will be undefined if the search takes too long
        return panToDeviceByID(idOfDeviceToPanTo).catch((e) => {
            console.warn(`Error with panning to selected device ${idOfDeviceToPanTo}`, e);
        });
    }
    // if we are going to pan to a field we should do that
    if (idOfFieldToPanTo) {
        return panToFieldByID(idOfFieldToPanTo).catch((e) => {
            console.warn(`Error with panning to selected field ${idOfFieldToPanTo}`, e);
        });
    }

    // if nothing above, just show the whole account
    return doFallbackMapPan();
}

export async function doFallbackMapPan() {
    await MapLoadedPromise;
    FocusContext.setAOI(undefined);

    // if there are no fields on the account, use the default position.
    // @ts-ignore
    if (MasterIndex.aoi.all.length === 0) {
        FocusContext.MapViewToPoint(...getStartingPositionOfMap());
    }
}
/**
 * Pan to the field based on the id. Handles making sure map is loaded and everything so this happens smoothly
 * @param idOfFieldToPanTo
 * @return {Promise<void>}
 */
async function panToFieldByID(idOfFieldToPanTo: string) {
    VerdiAPI.MasterIndex.aoi
        .byIDeventually(idOfFieldToPanTo)
        .then(async (newActiveAOI) => {
            // preset the location we are going to go to so that if the map initializes, it uses this
            const center = getCenterOfPolygon(newActiveAOI.polygon);
            setStartingPositionOfMap(center[0], center[1]);

            // Actually wait for the map to load and set the AOI
            if (newActiveAOI) {
                setTimeout(async () => {
                    try {
                        await MapLoadedPromise;
                        FocusContext.setAOI(newActiveAOI);
                    } catch (e) {
                        console.warn("couldn't set aoi, not loaded");
                    }
                }, 100);
            }
        })
        .catch(async () => doFallbackMapPan());
}

/**
 * Pan to a device based on the id. Handles making sure map is loaded and everything so that this happens smoothly
 * @param idOfDeviceToPanTo
 * @return {Promise<void>}
 */
async function panToDeviceByID(idOfDeviceToPanTo: string) {
    // deviceModelToPanTo will be undefined if the search takes too long
    // we use a race so that, if it takes longer than a certain amount of time to load the device, it just load the default view while it waits
    const deviceModelToPanTo = await Promise.race([
        findDeviceByIDEventually(idOfDeviceToPanTo).then(async (newActiveDevice) => {
            if (newActiveDevice) {
                // preset the location we are going to go to so that if the map initializes, it uses this
                setStartingPositionOfMap(newActiveDevice.lat, newActiveDevice.lng);

                console.info("found matching device", newActiveDevice);

                // wait until this device is on the map and there is a leaflet element
                let attempts = 0;
                const maxAttempts = 40;
                while (
                    attempts < maxAttempts &&
                    !FocusContext.MapEntitesByModelID[newActiveDevice.id] &&
                    FocusContext.MapEntitesByModelID?.[newActiveDevice.id]?.onMap &&
                    FocusContext.MapEntitesByModelID?.[newActiveDevice.id]?.leafletElement
                ) {
                    await sleep(10 + attempts * attempts);
                    attempts++;
                }
                if (attempts >= maxAttempts) {
                    throw new Error(
                        `Error, could not find device map entity for id ${newActiveDevice.id}. It failed to load`,
                    );
                }

                const mapEntityOfDevice: MapEntityBase = FocusContext.MapEntitesByModelID[newActiveDevice.id];
                console.info("found Map entity for device", mapEntityOfDevice);
                // wait for the map to load
                await MapLoadedPromise;

                // spawn the info card
                FocusContext.defaultContext.onInteract(
                    // @ts-ignore
                    {},
                    mapEntityOfDevice,
                    FocusContext.defaultContext,
                );

                // without this, this fails sometimes
                await sleep(50);
                // fly to the map entity
                FocusContext.MapViewToEntity(mapEntityOfDevice);

                if (newActiveDevice.linkedAreaOfInterest) {
                    FocusContext.setAOI(FocusContext.MapEntitesByModelID[newActiveDevice.linkedAreaOfInterest], {
                        panToAOI: false,
                    });
                }
                console.info("pushed interaction with device map entit");
            }
            return newActiveDevice;
        }),
        sleep(600),
    ]);

    // if the sleep promise returned in the race condition instead of the device one, then just pan the map out until the
    // device is found.
    if (!deviceModelToPanTo) {
        doFallbackMapPan();
    }
}

export async function doInitialScheduleLoad() {
    // run asynchronously because it's heavy and doesn't need to be loaded right away
    await sleep(300);
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const defaultSchedule = new Schedule(
        new Date(Date.now() - 1000 * 60 * 60 * 24 * 20),
        new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
        () => true,
    );

    await sleep(200);
    doTick();
    await sleep(200);
    doTick();
}
