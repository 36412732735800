import { clamp } from "gardenspadejs/dist/general";
import React from "react";
import { HistoricalDataResponse } from "verdiapi/dist/APICommands/HistoricalDataTypes";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { DataTypeSpecifier, ParsedDataTypeSpecifier } from "verditypes";

import { PrettifiedDataTypeByDataType } from "./multidepthGraphConstants";
import { prettifyDepth } from "./MultiDepthSoilMoistureUtils";
import { SentekSoilMoistureGraphDataStream } from "./types";

type ModifiedHistoricalDataResponse = Omit<HistoricalDataResponse, "data"> & {
    data: {
        name: string;
        dateValue: number;
        value: number[];
    }[];
    position: { depth: number };
    idOfNextLowestDepthDataStream?: string;
    boostNeededToStayAboveLowerDepthDataStream?: number;
    mostRecentlyAddedValue?: number;
    range: [number, number];
};

export const useMultiDepthDataProcessing = ({
    numDatapoints,
    historicalDatabase,
}: {
    numDatapoints: number;
    historicalDatabase: HistoricalDataBase;
}): {
    allDataSeriesArray: SentekSoilMoistureGraphDataStream[];
    dataSeriesByID: Record<string, SentekSoilMoistureGraphDataStream>;
} => {
    const [allDataSeriesArray, setAllDataSeriesArray] = React.useState<SentekSoilMoistureGraphDataStream[]>([]);
    const [dataSeriesByID, setDataSeriesByID] = React.useState<Record<string, SentekSoilMoistureGraphDataStream>>({});

    React.useEffect(() => {
        // create a look up of each modified response based on the data stream ID so we can add points to them
        const modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse> = {
            ...historicalDatabase.dataKeyMetadataLookup,
        } as unknown as Record<string, ModifiedHistoricalDataResponse>;

        modifiedHistoricalDataResponseByID.sumOfMoisture = generateEmptySumOfMoistureModifiedDatastream(
            modifiedHistoricalDataResponseByID,
        );

        addModifiedFieldsToDataResponse(modifiedHistoricalDataResponseByID);

        const lastDataPoint =
            historicalDatabase.data.value[historicalDatabase.data.value.length - 1]?.getAllData() ?? {};

        // initialize current moisture at each depth for future calculation
        const curMoistureAtEachDepth: Record<number, number> = {};
        Object.entries(modifiedHistoricalDataResponseByID).forEach(([key, dataStream]) => {
            const depth = dataStream.position?.depth;
            // @ts-ignore
            if (modifiedHistoricalDataResponseByID[key].dataType === "moisture_drillAndDrop") {
                // @ts-ignore
                curMoistureAtEachDepth[depth] = lastDataPoint?.[key] ?? 0;
            }
        });

        const maxDepth = Math.max(...Object.keys(curMoistureAtEachDepth).map((x) => parseFloat(x)));

        const depthChangeBetweenReadings = maxDepth / Object.keys(curMoistureAtEachDepth).length;
        // initialize on the first point
        let curPoint = historicalDatabase.data.value[historicalDatabase.data.value.length - 1];
        // itterate through every point going backwards
        while (curPoint?.prevPoint) {
            const sumOfCurrentMoistureReadings =
                (Object.values(curMoistureAtEachDepth).reduce((a, b) => a + clamp(b, 0, 100), 0) *
                    depthChangeBetweenReadings) /
                100;

            // eslint-disable-next-line @typescript-eslint/no-loop-func
            Object.entries({
                ...curPoint.data,
                // @ts-ignore
                sumOfMoisture: sumOfCurrentMoistureReadings,
                // eslint-disable-next-line @typescript-eslint/no-loop-func
            }).forEach(([key, value]) => {
                // if this is a metadata key or unrelated to the data streams we are processing, just return
                if (!modifiedHistoricalDataResponseByID[key]) {
                    return;
                }

                const dataStreamForPoint = modifiedHistoricalDataResponseByID[key];

                // @ts-ignore moisture is the datatype used for summing the moisture and therefore shouldn't be included in the per depth stuff
                if (dataStreamForPoint.dataType.includes("moisture") && dataStreamForPoint.dataType !== "moisture") {
                    // @ts-ignore
                    curMoistureAtEachDepth[dataStreamForPoint.position?.depth || 0] = value;
                }

                if (!dataStreamForPoint.data) {
                    dataStreamForPoint.data = [];
                }

                if (dataStreamForPoint.data) {
                    // @ts-ignore
                    dataStreamForPoint.data.push({
                        name: "test",
                        // @ts-ignore
                        dateValue: curPoint.date.valueOf(),
                        // @ts-ignore
                        value: [curPoint.date.valueOf(), value],
                    });
                }
                dataStreamForPoint.range[0] = Math.min(dataStreamForPoint.range[0], value);
                dataStreamForPoint.range[1] = Math.max(dataStreamForPoint.range[1], value);
                dataStreamForPoint.mostRecentlyAddedValue = value;

                // if there is a datastream below this one, update the boost as required to keep this datastream on top of the
                // one below it.
                if (dataStreamForPoint.idOfNextLowestDepthDataStream) {
                    // @ts-ignore
                    const nextLowestDataStream =
                        modifiedHistoricalDataResponseByID[dataStreamForPoint.idOfNextLowestDepthDataStream];
                    if (
                        nextLowestDataStream.mostRecentlyAddedValue !== undefined &&
                        dataStreamForPoint.mostRecentlyAddedValue !== undefined
                    ) {
                        dataStreamForPoint.boostNeededToStayAboveLowerDepthDataStream = Math.max(
                            dataStreamForPoint.boostNeededToStayAboveLowerDepthDataStream ?? -10000,
                            nextLowestDataStream.mostRecentlyAddedValue - dataStreamForPoint.mostRecentlyAddedValue,
                        );
                    }
                }
            });
            // @ts-ignore
            curPoint = curPoint.prevPoint;
        }
        const newDataSeriesByID: Record<string, SentekSoilMoistureGraphDataStream> = {};

        const newDataSeries = convertModifiedDataResponsesToDataSeries(
            modifiedHistoricalDataResponseByID,
            newDataSeriesByID,
        );

        setAllDataSeriesArray(newDataSeries);

        // Most of the below is a patch for an issue in eCharts. A cacheing problem means changing
        // object references creates duplicate data. To solve it, we need to maintain object references, but change the
        // properties and avoid creating any duplicate data points.

        // to preserve our original object references, we clone the base data series object
        const cachedDataSeriesObject = { ...dataSeriesByID };

        // We then clear the original object to maintain the reference while resetting the data
        Object.keys(dataSeriesByID).forEach((key) => {
            delete dataSeriesByID[key];
        });

        /**
         * We need to add data to the data array carefully, because duplicate data points cause problems because of
         * some improper cacheing that happens on the eCharts side
         * @param dataToAdd
         * @param originalArray
         */
        function addDataToDataArray(
            dataToAdd: Array<{ dateValue: number }>,
            originalArray: Array<{ dateValue: number }>,
        ): void {
            // @ts-ignore
            const newData = dataToAdd.filter(
                (newDataPoint) =>
                    !originalArray.some(
                        (existingPoint) =>
                            // @ts-ignore
                            existingPoint.dateValue === newDataPoint.dateValue,
                    ),
            );
            originalArray.push(...newData);
            originalArray.sort((a: { dateValue: number }, b: { dateValue: number }) => a.dateValue - b.dateValue);
        }

        // we go through each data series and add the new data to the old data array and then write the new data series properties over
        // the old ones on the same object reference
        Object.entries(newDataSeriesByID).forEach(([key, value]) => {
            const originalDataArray = cachedDataSeriesObject[key]?.data ?? [];
            addDataToDataArray(newDataSeriesByID[key]?.data, originalDataArray);

            value.data = originalDataArray;
            dataSeriesByID[key] = Object.assign(cachedDataSeriesObject[key] ?? {}, value);
        });

        // now we se the data series to finish the job
        setDataSeriesByID(dataSeriesByID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numDatapoints]);

    return {
        allDataSeriesArray,
        dataSeriesByID,
    };
};

function generateEmptySumOfMoistureModifiedDatastream(
    modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse>,
): ModifiedHistoricalDataResponse {
    return {
        // bootlegs the owner, source , relevant zones, etc. from an aribtrary datastream so that they aren't undefined.
        ...modifiedHistoricalDataResponseByID[Object.keys(modifiedHistoricalDataResponseByID)[0]],

        boostNeededToStayAboveLowerDepthDataStream: 0,
        data: [],
        idOfNextLowestDepthDataStream: undefined,
        mostRecentlyAddedValue: undefined,
        position: {
            lat: 0,
            lng: 0,
            depth: 0,
        },
        range: [100000000, -100000000],
        dataType: "moisture",
    };
}

/**
 * Adds the fields required in a modified response based on metadata. Specifically the range a
 * @param modifiedHistoricalDataResponseByID
 */
function addModifiedFieldsToDataResponse(
    modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse>,
) {
    // for each historical data response, we add the fields we defined above to make it a "modified" response
    Object.entries(modifiedHistoricalDataResponseByID).forEach(([key, dataStream]) => {
        // range defaults with extremely positive min and extremely negative max
        modifiedHistoricalDataResponseByID[key].range = [100000000, -100000000];

        const depth = dataStream.position?.depth;

        // find the data stream underneath this one that is of the same data type
        dataStream.idOfNextLowestDepthDataStream = getIDOfHighestDatastreamUnderDepth(
            depth,
            dataStream.dataType,
            modifiedHistoricalDataResponseByID,
        );
    });
}

function convertModifiedDataResponsesToDataSeries(
    modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse>,
    newDataSeriesByID: Record<string, SentekSoilMoistureGraphDataStream>,
) {
    const newDataSeries: SentekSoilMoistureGraphDataStream[] = Object.entries(modifiedHistoricalDataResponseByID).map(
        ([id, ds]) => {
            ds.data.sort((a, b) => a.dateValue - b.dateValue);

            let name = `${PrettifiedDataTypeByDataType[ds.dataType as DataTypeSpecifier] ?? ds.dataType} ${prettifyDepth(ds.position?.depth)}`;
            if (ds.dataType === "moisture") {
                name = "Summed Moisture";
            }
            newDataSeriesByID[id] = {
                name: name,
                type: "line",
                showSymbol: false,
                data: ds.data,
                average: "average",
                dataType: ds.dataType,
                depth: ds.position?.depth,
                boostNeededToStayAboveLowerDepthDataStream: ds.boostNeededToStayAboveLowerDepthDataStream ?? 0,
                range: ds.range,
                id: id,
            };
            return newDataSeriesByID[id];
        },
    );
    return newDataSeries;
}

/**
 * Returns the ID of the data stream with a depth just below the depth given.
 * @param depth
 * @param dataType
 * @param modifiedHistoricalDataResponseByID
 * @return {string | undefined}
 */
const getIDOfHighestDatastreamUnderDepth = (
    depth: number,
    dataType: ParsedDataTypeSpecifier,
    modifiedHistoricalDataResponseByID: Record<string, ModifiedHistoricalDataResponse>,
): string | undefined => {
    // if depth is not given, then there is no highest data stream under this depth
    if (depth === undefined || Number.isNaN(depth)) {
        return undefined;
    }

    // find the data stream underneath this one that is of the same data type
    const nextLowest = Object.entries(modifiedHistoricalDataResponseByID)
        // only data streams with the same data type
        .filter(([_id, candidateNextLowest]) => candidateNextLowest.dataType === dataType)
        // only data streams that are lower
        .filter(([_id, candidateNextLowest]) => (candidateNextLowest.position?.depth ?? 0) > depth)
        // pick the one that is the l
        .reduce<[string, ModifiedHistoricalDataResponse] | undefined>((champEntry, challengerEntry) => {
            if (!champEntry) {
                return challengerEntry;
            }
            // return the one that has a smaller depth
            if (champEntry[1].position?.depth < challengerEntry[1].position.depth) {
                return champEntry;
            }
            return challengerEntry;
        }, undefined);

    return nextLowest?.[0];
};
